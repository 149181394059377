 
//Top Vars Above

var bloggerMay21 = function () {

  var blogpost14= `
  <p class="firstparagraph">
    Data, unstructured and unrefined, serves little purpose for 
    machine-learning training data: Contextual data, semi-structured, 
    is the proper nutrition for the refinement of AI algorithm development.  
    Petabytes of data come at a cost, notwithstanding!</p>
  </p>
  <p class="quote">AI has spawned exciting breakthroughs in the past 
  decade—programs that can beat humans at complex games, steer cars through 
  city streets under certain conditions, respond to spoken commands, and write 
  coherent text based on a short prompt. Writing in particular relies on recent advances in computers’ ability to parse and manipulate language.
  <br /><br />
  Those advances are largely the result of feeding the algorithms more text as 
  examples to learn from, and giving them more chips with which to digest it. And 
  that costs money.
  <br /><br />
  Consider OpenAI’s language model GPT-3, a large, mathematically simulated neural 
  network that was fed reams of text scraped from the web. GPT-3 can find 
  statistical patterns that predict, with striking coherence, which words 
  should follow others. Out of the box, GPT-3 is significantly better than 
  previous AI models at tasks such as answering questions, summarizing text, 
  and correcting grammatical errors. By one measure, it is 1,000 times more capable than its predecessor, GPT-2. But training GPT-3 cost, by some estimates, almost $5 million.</p>
`;
var blogcite14 = `

<p class="footnotes">1. <a href="https://www.wired.com/story/ai-smarts-big-price-tag/"   target="_blank"> https://www.wired.com/story/ai-smarts-big-price-tag/</a>
 
 
</p>   `;

var blogpost13= `
<p class="firstparagraph">M1 Chip, as most know, signifies an architectural departure from traditional laptop design. Normally, the CPU, RAM, I/O, etc. are separate components. M1 combines all these chips into 1 chip, the M1. <br /><br />
The M1 design is not entirely novel, as it is the architecture already powering iPhone/iPad. This year's event will unveil the next iteration, M1X MacBook Pro, along with a redesign. Sadly, this article makes a great point that supply chain problems will likely rain on the parade... 
I'm still waiting another month for my iPhone 13 Max to arrive! </p>
</p>
<p class="quote">“The PC industry continues to be hampered by supply and 
logistical challenges and unfortunately these issues have not seen much 
improvement in recent months,” said IDC analyst Jitesh Ubrani in a recent report. <br /><br />
In a separate report, Canalys analyst Ishan Dutt echoed this same thought, saying, 
“The shortfall in supply of PCs is expected to last well into 2022, with the 
holiday season of this year set to see a significant portion of orders not met.”
 
 </p>
`;
var blogcite13 = `
 
<p class="footnotes">1. <a href="https://appleinsider.com/articles/21/10/14/new-macbook-pros-are-coming-oct-18-and-the-design-remains-a-mystery"   target="_blank">
https://appleinsider.com/articles/21/10/14/new-macbook-pros-are-coming-oct-18-and-the-design-remains-a-mystery</a>
</p>  

<p class="footnotes">2. <a href="https://www.macworld.com/article/544115/m1x-macbook-pro-supply-chain-demand.html"   target="_blank">
https://www.macworld.com/article/544115/m1x-macbook-pro-supply-chain-demand.html</a>
</p> 
<p class="footnotes">3. <a href="https://www.macrumors.com/how-to/watch-october-18-apple-event/"   target="_blank">
https://www.macrumors.com/how-to/watch-october-18-apple-event/</a>
</p>   `;


  var blogpost7 = `
  <p class="firstparagraph">
   European and Russian Crypto powers can only rejoice upon news of another regime weighing cryptocurrency over-regulation<sup>1</sup> and possible suppression. 
    Future organization of global trustless transactions is inextriably linked to distributed ledger "blockchain" technologies. Whether markets or government institutios, it is the early adopters of Crypto technologie (and assets) will hold the higher ground. <br />Never one to shy away from new Technology, the land of the pioneering explorers has of recent turned Luddite, fearing change. Same as the cliff diver that 
   hesitates before a jump, only a rock and hard place are the new dreaded options.
  </p>
  <p class="quote">Crypto market capitalization is the total value of a cryptocurrency. Where stock market capitalization i
  s calculated by multiplying share price times shares outstanding, 
  crypto market capitalization is calculated by multiplying the price of the cryptocurrency with the number of coins in circulation. </p>
`;
var blogcite7 = `

<p class="footnotes">1. <a href="https://decrypt.co/82997/biden-crypto-executive-order"   target="_blank">decrypt.co/82997/biden-crypto-executive-order</a>
 
 
</p>   `;

  var blogpost7 = `
  <p class="firstparagraph">
   European and Russian Crypto powers can only rejoice upon news of another regime weighing cryptocurrency over-regulation<sup>1</sup> and possible suppression. 
    Future organization of global trustless transactions is inextriably linked to distributed ledger "blockchain" technologies. Whether markets or government institutios, it is the early adopters of Crypto technologie (and assets) will hold the higher ground. <br />Never one to shy away from new Technology, the land of the pioneering explorers has of recent turned Luddite, fearing change. Same as the cliff diver that 
   hesitates before a jump, only a rock and hard place are the new dreaded options.
  </p>
  <p class="quote">Crypto market capitalization is the total value of a cryptocurrency. Where stock market capitalization i
  s calculated by multiplying share price times shares outstanding, 
  crypto market capitalization is calculated by multiplying the price of the cryptocurrency with the number of coins in circulation. </p>
`;
var blogcite7 = `

<p class="footnotes">1. <a href="https://decrypt.co/82997/biden-crypto-executive-order"   target="_blank">decrypt.co/82997/biden-crypto-executive-order</a>
 
 
</p>   `;

  
  var blogpost6 = `
  <p class="firstparagraph">Hex<sup>0</sup> white papers<sup>3</sup> reveal a seemingly ordinary DeFi Ethereum smart contract, 
  which by design is the first Certificate of Deposit. An interesting side-effect incentivizes hodling the Hex token, 
  rather than trading it or selling it, thereby dropping price. This behavior is by definition the single most effective factor on dictating a token's Market Cap value: More buyers (and hodlers) than sellers ...</p>
  <p>Therefore, Hex, ranked #3 in Market Cap<sup>1</sup>, is the only crypto that can drive up in value, but cannot drop in price ... without quickly being bought up: This is <i>because there will never be enough sellers to outmatch the widening base of Hex buyers, and of course the Hex staker-hodlers!</i>
  
<p class="quote"> </p>
`;
var blogcite6 = `

<p class="footnotes">0. <a href="https://hex.com"   target="_blank">https://hex.com</a>
<br />1. <a href="https://nomics.com"   target="_blank">https://nomics.com</a></p>
<h6>Pre-2019 immutable record of Hex Citations, White Papers, Security & Financial Audits</h6>
<p class="footnotes">
2. <a href="hex.com/techspecs"   target="_blank">hex.com/techspecs</a>
<br />3. <a href="https://docs.google.com/document/d/1P0ZDaBQx4ghkdX5IUwZb1n8ThvYf7i22MSt9Gm00JRU/edit"   target="_blank">Hex Smart Contract</a>
<br />4. <a href="https://hex.com/docs/HEX-Security-Audit-by-CoinFabrik-DEC2019.pdf"   target="_blank">Coinfabric security audit</a>
<br />5. <a href="https://hex.com/docs/HEX%20Economics%20Audit%20by%20CoinFabrik.pdf"   target="_blank">Coinfabric financial audit</a></p>


 
 
</p>   `;
 
  var blogpost5 = `
  <p class="firstparagraph">Since October 1st I have felt very bullish on both Bitcoin and Ethereum. As it happens, their respective price values have rose from 48k to 55k, & Ethereum's 3k to 3.5k. 
  September is always a bad month for crypto. </p><p>More importantly, what drives the utility of the Ethereum Token is its irreducible requirement to run, operate, and transact any smart contracts on the Ethereum Virtual Machine, i.e., distributed, global Operating System.
  </p>
 <p>While Banks, Financial management, Hedge Funds, and the lot are busy buying into Smart Contracts, the only barrier to cross-chain leverage trading is the common
  denominator of real-time, global price updates: for which only a trusted 3rd party can triangulate common prices. For this reason, Chainlink's promise of continuing its hegemony over DeFi data sharing contracts. Chainlink will make this happen.
  </p>
    
<p class="quote">Oracles from Chainlink, a provider of data feeds to smart contracts, have been integrated into Ethereum scaling solution Arbitrum One.</i><sup>1</sup> </p>
 <ul ><li  >
  Chainlink's U.S. dollar-denominated price feeds are available now, with support for new price pairs to follow, according to an announcement shared with CoinDesk Thursday.
  </li><li>Arbitrum says it is addressing a common request from smart-contract developers, who require high-quality data from financial markets to power decentralized exchanges, futures and options platforms and algorithmic stablecoins.
  </li><li>The Chainlink feeds provide Arbitrum with high-frequency price updates allowing assets to be valued on-chain and in real time.
  </li><li class="quote">Arbitrum says developers will be able to receive the same data quality and reliability as on the Ethereum base layer, but at lower cost and with more frequent updates.</li>
  </ul>
  </p>
`;
var blogcite5 = `
<p class="footnotes">1. <a href="https://www.coindesk.com/markets/2021/08/12/chainlink-oracles-become-available-on-ethereum-scaler-arbitrum-one/"   target="_blank">https://www.coindesk.com/markets/2021/08/12/chainlink-oracles-become-available-on-ethereum-scaler-arbitrum-one/</a>
<br /><a href="https://chain.link/"   target="_blank">https://chain.link/</a>
 
</p> 
  `;
 
  var blogpost4 =  ` 
  <p class="firstparagraph">Financial Institutions' acceptance and adoption (i.e., BofA,Visa,PayPal,GrayScale,...) resembles the retail's interest and adoption resulting in the 2017 run-up. More importantly, interest in actual utility (smart contraacts and distributed ledger's utility in mediating real estate, titles, trusts, etc.) and applicability differentiates the current era.  </p>

  <p class="quote"><i>“In the near future, you may use blockchain technology to unlock your phone; buy a stock, house or fraction of a Ferrari; receive a dividend; borrow, loan or save money; or even pay for gas or pizza,” the report states.

  Along the way, Bank of America cites sizable figures—such as more than $17 billion worth of venture capital investments in digital assets and blockchain firms in the first half of 2021 alone, compared to $5.5 billion across all of 2020.
   </i><sup>1</sup> </p>
   <p>The article further outlines assets that outstrip the mere market value of the tokens themselves: After all, doesn't the value of a socio-economic disruption underlie the mere, simplistic value of the token's market cap? 
   Distributed <i>(decentralized)</i> Applications (DAPPs) represent a new means of accomplishing a formerly centralized task: i.e. government ID's, titles, etc.) </p>
   <p class="quote"><i> ... the report states that the “digital asset sector [is] too large to ignore,” and that there’s more to watch beyond Bitcoin. “We believe crypto-based digital assets could form an entirely new asset class,” the report reads. 
   “Bitcoin is important with a market value of ~$900 billion, but the digital asset ecosystem is so much more.” </i><sup>1</sup> </p>
<p>So now, we need to alter our favorite cliche, "There's a DAPP for that!</p>"
    `;
  var blogcite4 = `
  <p class="footnotes">1. <a href="https://decrypt.co/82573/bank-of-america-bullish-bitcoin-ethereum-defi-nfts"   target="_blank"> https://decrypt.co/82573/bank-of-america-bullish-bitcoin-ethereum-defi-nfts</a>
  </p>   `;
   

var blogpost3 =  ` 
<p class="firstparagraph">This year does feel reminiscingly like 4th quarter 2017 when Ethereum price jumped from 300's to 1400. Whatever the direction and timing Ethereum's price point in the 4th quarter, the utility of Ethereum\'s "gas" is the true value: Ethereum gas exclusively runs all Ethereum-based tokens (ERC-20) circulating as smart contracts on the Ethereum Virtual Machine. 
After all, any of the tokens on Ethereum network will always require Ether to transact, swap, send or any other smart contract function. This article covers the finer points of Ethereum's current value.<br />
<br />Technical Indicators<br />
1. Stochastic RSI - compares its closing price with the price range over a given period. (a reading above 80 is considered excessively bought and below 20 is considered excessively sold)<br />
2. Relative Strength Index (RSI) - measures the magnitude of recent price changes to evaluate overbought or oversold conditions in the price of a stock or other asset. The RSI is displayed as an oscillator (a line graph that moves between two extremes) and can have a reading from 0 to 100.<br />
3. Bullish Hammer - candle that indicates the likely bottom of a trend. It is characterized by a long wick at the bottom with a short body on top. That shows that bears pushed the price down during the time frame, only to see significant buying pressure step in to close higher than the candle open. <br />
4. Fibonacci retracement level - Method for determining support and resistance levels. It is named after the Fibonacci sequence of numbers, whose ratios provide price levels to which markets tend to retrace a portion of a move, before a trend continues in the original direction.
<br />
</p>
 
`;
var blogcite3 = `
<p class="footnotes"> <a href="https://cointelegraph.com/news/ethereum-fractal-from-2017-that-resulted-in-7-000-gains-for-eth-appears-again-in-2021"   target="_blank">https://cointelegraph.com/news/ethereum-fractal-from-2017-that-resulted-in-7-000-gains-for-eth-appears-again-in-2021 </a>
</p>   `;
 
var blogpost2 =  ` 
<p class="firstparagraph">Ethereum's world virtual machine has proven utility already, and only the tip of the iceberg has been reached for potential. DeFi applications have locked 2/3 Ethereum, which boosts from $600 to $4000, summing to market cap of $388,000,000. <img src="dist/img/ada-btc-eth.png" class="zoom"   /> <p><i><small>Matic vs Ada vs ETH vs BTC performance (source: TradingView)</small></i></p> <p>The interesting matter is Ethereum's DeFi applicibility--from real estate to pools and loans--based on sophisticated smart contracts. Bitcoin, aside from form of value, has no parallel attraction...
`;
var blogcite2 = `
<p class="footnotes">1. <a href="https://www.benzinga.com/node/21184597"   target="_blank">Is the Bitcoin-Ethereum 'Flippening' a Likely Scenario?</a>
</p>   `;
 

var blogpost1 =  ` 
<p>Doubtless, New Chain Lifecycle is 5% utility, 5% hype and 90% Decentralized App developers' community -- the ingredient <i>par excellence</i> for lasting hype and adoption. Here we have the exponential growth of Ethereum interest, let alone price of token. On the contrary we have Cardano Network, powered by Proof-of-Stake ADA token, which has yet to evolve past TestNet phase, a point of paralysis for Cardano chain DAPP development. One last step before final main net launch, and finally, we can witness David face Goliath...</p>

<p class="quote"><i>This year, we are seeing the direct competitors clashing. Ethereum has made great strides to make it scalable and affordable, with the Berlin fork already finalized and London hard fork scheduled for July.
In the meantime, Cardano smart contracts should be entering the Cardano testnet by the end of this month.</i><sup>1</sup> 
</p>
<p>Better late than never, the Cardano blockchain ecosystem boasts superior, more efficient, sophisticated smart contracting:</p>
<p class="quote">Cardano (ADA) is a highly anticipated direct competitor to Ethereum, promising more scalability and smart contracts with the Alonzo upgrade.</p>
<p>There are two obstinate problems:<i>Ethereum's monopolistic and universal DAPP evolution and ADA's stunted community development.</i></p>
<p>First, All customized business applications, whether off-chain or on-, have built architectures depending on private/consortium blockchains that periodically sync with Ethereum mainnet. </p><p>Secondly, whether or not Cardono does launch main net, the multi-year delay in even <i>launching</i>chain mainnet --for all its bells and whistles -- has stunted new development and developer solidarity. Despite the author's praise for ADA, he concedes the infinitesimally small likelihood that Cardano developers will ever approach Ethereum network --</p> 
<p class="quote">Lastly, despite getting up to 7th rank, Cardano (ADA) is not likely to catch up with Ethereum’s much larger dev pool and network effect.<sup>1</sup></p>
<p>And so, beyond Ethereum 2's Proof-of-Stake transition on the near horizon, ethereum enjoys a burgeoning ecosystem of layer-2 sidechains, facilitated by the likes of those as Polygon (Matic) solutions. Even so, next to Polkadot ecosystem, only Cardano stands as a viable challenge, how ever miniscule it may be...</p>
<p class="quote">
*Ethereum holds (by far) the largest pool of developers, according to Electric Capital. As a number of open source dev ops tools are available to make remote work easier through collaboration, managing developers remains a serious cog in DeFi development. Yet Ethereum and its developer community have thus far been dominant in this sense.<br /><br />
*In the last three years, Ethereum has widened its developer pool by 215%. Such a network effect would be exceedingly difficult to overcome.</i><sup>2</sup> 
</p></p>
`
var blogcite1 = 
`
 
<p class="footnotes">1. <a href="https://finance.yahoo.com/news/cardano-prepares-launch-alonzo-testnet-130000976.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAKH0bKJvZsFbQBykBexupKV-HvvhwSDQjmlBmyM4Q69AaNPYvc4Tk8LSJIyBqekdp0PAPL7kz0hTgJFEwOB3jchU4gzkApL6bQay9JZ2z16ddSW5RsCYEp049RRj-L_zFhrhze_83BrLCQjxz_-oOvLEbc8l3X_T4KdO8-eQf6cz"   target="_blank">cardano-prepares-launch-alonzo-testnet</a>
</p>   
<p class="footnotes">2. <a href="https://www.benzinga.com/node/21184597"   target="_blank">Is the Bitcoin-Ethereum 'Flippening' a Likely Scenario?</a>
</p>
`; 
   
  var url =  
  [      { 
    id:'14',
    did:'21-10-14',
    date:'October 14, 2021',
    author:'by Thomas Maestas, MA',
    cat3:'A.I.Now.',
    title:'Data is the New Oil, Part I: <br /> AI\'s dependence on structured data',
    post:blogpost14,
   blogcite:blogcite14
   },
   { 
    id:'13',
    did:'21-10-13',
    date:'October 13, 2021',
    author:'by Thomas Maestas, MA',
    cat3:'Web Dev Affairs',
    title:'Unveiling of the M1X MacBook Pro',
    post:blogpost13,
   blogcite:blogcite13
   },
    { 
      id:'7',
      did:'21-10-07',
      date:'October 7, 2021',
      author:'by Thomas Maestas, MA',
      cat3:'Musing Blockchain',
      title:'Blockchain Miracles, Part III:<br />Freedom to Innovate on Blockchain is a Critical Function of 21st century Democracy',
      post:blogpost7,
     blogcite:blogcite7
     }, 
    { 
      id:'6',
      did:'21-10-06',
      date:'October 6, 2021',
      author:'by Thomas Maestas, MA',
      cat3:'Musing Blockchain',
      title:'Blockchain Miracles, Part II: CDs<br />Hex\'s utility as the First Blockchain CD and Building Innate Resistance to Price Drops',
      post:blogpost6,
     blogcite:blogcite6
     },     
    { 
      id:'5',
      did:'21-10-05',
      date:'October 5, 2021',
      author:'by Thomas Maestas, MA',
      cat3:'Musing Blockchain',
      title:'Blockchain Miracles, Part I: Oracles<br />Chainlink\'s Real Time Data and the secret to Ethereum\'s DeFi Network (Decentralized Finance) ',
      post:blogpost5,
     blogcite:blogcite5
     },     
       { 
      id:'4',
      did:'21-10-04',
      date:'October 4, 2021',
      author:'by Thomas Maestas, MA',
      cat3:'Musing Blockchain',
      title:'Ethereum\'s Institutional DAPP Coronation',
      post:blogpost4,
     blogcite:blogcite4
     },     
    { 
    id:'3',
    did:'21-10-01',
    date:'October 1, 2021',
    author:'by Thomas Maestas, MA',
    cat3:'Musing Blockchain',
    title:'Ethereum\'s Next Leap Year',
    post:blogpost3,
   blogcite:blogcite3
   },
 
     { 
      id:'2',
      did:'21-05-18',
      date:'May 18, 2021',
      author:'by Thomas Maestas, MA',
      cat3:'Musing Blockchain',
      title:'ETH v. BTC v. ADA, Part I: Ethereum\'s Law of Large Numbers',
      post:blogpost2,
     blogcite:blogcite2
     },
    { 
    id:'1',
    did:'21-05-17',
    date:'May 17, 2021',
    author:'by Thomas Maestas, MA',
    cat3:'Web Dev Affairs',
    title:'ETH v. BTC v. ADA, Part I: Varying levels of DAPP Community-Building ',
    post:blogpost1,
   blogcite:blogcite1
   }
   ] ;

   for (i=0;i<url.length;i++){ 
    var cat = ` 
    <div id="${url[i].did}" class="blogDiv"> 
    <hr />  
    <a href="#top"><button>Top</button></a>  
    <h4 class="title dailytech longtitle">OUR DAILY TECH:</h4>  
    <h5 id="cat3" class="  subdailytech">${url[i].cat3}</h5> 
    <p id="author" class="  author">${url[i].author}</p>   
    <h6  id="date" class="  chapternumber">${url[i].date}</h6>    
    <h6  id="title"   class="dailytitle chapternumber">${url[i].title}</h6>
    <br />
    <div id="post">${url[i].post}
    </div>
    <div id="blogcite">${url[i].blogcite}
    </div>` ;
document.getElementById("paragraph-may21").innerHTML += cat; 
} 
 
var i;
for (i=0;i<url.length;i++){ 
  var catMod = `
  <div id="${url[i].did}_mod" class="blogDivMod"> 
  <hr />  
  <a href="#top-mod"><button>Top</button></a>   
  <h4 class="title dailytech longtitle">OUR DAILY TECH:</h4>  
  <h5 id="cat3" class="  subdailytech">${url[i].cat3}</h5>  
  <p id="author" class="  author">${url[i].author}</p>   
  <h6  id="date" class="  chapternumber">${url[i].date}</h6>    
  <h6  id="title"   class="dailytitle chapternumber">${url[i].title}</h6>
  <br />
  <div id="post">${url[i].post}</div>
  </div>
 <div id="blogcite">${url[i].blogcite}
 </div>`;
document.getElementById("paragraph-mod-may21").innerHTML += catMod;
}


console.log('blogger-may21'); 
console.log(angular.toJson(url));
}; 


bloggerMay21();  

